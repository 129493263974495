import React from 'react';
import '../index.css';
import img from '../images/telemarketing.svg'; 
import img2 from '../images/sms.svg';
import img3 from '../images/email.svg';
import img4 from '../images/socialmedia.svg';
import img5 from '../images/supply.svg';
import img6 from '../images/assess.svg';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-purple-900 uppercase font-bold">servicios</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-purple-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-purple-900">Estamos profundamente comprometidos con el crecimiento y el éxito de tu negocio. Nos dedicamos a impulsar resultados efectivos y sostenibles, para que logres tus objetivos con confianza.</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-purple-900">Telemarketing</h2>
                                    <p className="text-md font-medium"> 
                                    Generamos citas mediante <b>telemarketing efectivo</b>, conectando en tiempo real con clientes potenciales y maximizando tus oportunidades de negocio. Nuestro enfoque es ideal para industrias en las que el contacto directo y la confianza son esenciales para cerrar ventas. Nos especializamos en alcanzar a la <b>comunidad latina</b>, asegurando que cada cita sea altamente calificada, relevante y en sintonía con tus objetivos comerciales.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-purple-900">SMS</h2>
                                    <p className="text-md font-medium">
                                    Aprovechamos el poder del <b>SMS para confirmar citas y mantener una comunicación ágil y efectiva</b> con tus clientes potenciales. Este método es perfecto para industrias que requieren actualizaciones rápidas y constantes. Al dirigirnos a la <b>comunidad latina</b>, nos aseguramos de que nuestros mensajes sean culturalmente relevantes, maximizando la tasa de respuesta y manteniendo a los clientes siempre informados y comprometidos con tu negocio.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-purple-900">Correo Electrónico</h2>
                                    <p className="text-md font-medium">
                                    Gestionamos la coordinación y confirmación de citas a través de <b>correo electrónico</b>, asegurando un proceso ágil y bien organizado. Este método es ideal para industrias en las que la <b>presición y la comunicación clara</b> son esenciales. Nos especializamos en conectar con la <b>comunidad latina</b>, adaptando cada mensaje culturalmente para garantizar el éxito en la confirmación de cada cita.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-purple-900">Redes Sociales</h2>
                                    <p className="text-md font-medium">
                                    Utilizamos las <b>redes sociales</b> para programar citas mediante mensajes directos y publicaciones estratégicas, generando una interacción en tiempo real y aumentando tu visibilidad. Este enfoque es ideal para industrias donde la <b>inmediatez y la conexión directa</b> son esenciales. Al conectar con la <b>comunidad latina</b> en las plataformas más populares, garantizamos que cada interacción sea relevante y que las citas se establezcan de manera eficaz.
                                    </p>
                                </div>
                            </div>                    
                        </div>
                    </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <img src={img5} alt="" className="img-style" />
                            </div>
                            <h3 className="text-3xl  text-purple-900 
                            font-bold">Nosotros <span className='font-black'>Suplimos</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                En <b>CommunityTech</b>, cubrimos la necesidad de una programación de citas eficiente y personalizada para negocios que buscan optimizar su tiempo y maximizar su rendimiento. Nuestros servicios están adaptados a una amplia variedad de industrias, conectando de manera efectiva con la <b>comunidad latina</b> para garantizar que cada cita sea relevante y esté bien gestionada. Nos enfocamos en ofrecer <b>soluciones prácticas</b> que faciliten el crecimiento y el éxito de nuestros clientes.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                
                                <img src= {img6} alt="" className="img-style"/>
                            </div>
                            <h3 className="text-3xl  text-purple-900 font-bold">Nosotros <span className='font-black'>Asesoramos</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>En <b>CommunityTech</b>, asesoramos a nuestros clientes en la <b>optimización de su proceso de programación de citas</b>, diseñando estrategias personalizadas que se alinean con sus objetivos comerciales. Gracias a nuestra experiencia en conectar con la <b>comunidad latina</b>, brindamos recomendaciones precisas y efectivas que mejoran la eficiencia y maximizan los resultados. Estamos comprometidos en guiar a nuestros clientes hacia un éxito sostenible mediante <b>soluciones prácticas</b> adaptadas a sus necesidades específicas.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;